<template>
  <Layout>
      <template #content>
        <div class="join_alone">
          <pageHead device="desktop" page="signup" :title="$t('store.btn.sign')" />
          <div class="join_alone-con">
            <div class="join_alone_bg">
              <div class="joinbg_li li_s1">
                <div class="nicon icon-point"></div>
                <p>{{ $t("store.footer02_title") }}</p>
              </div>
              <div class="joinbg_li li_s2">
                <div class="nicon icon-fast"></div>
                <p>{{ $t("store.footer03_title") }}</p>
              </div>
              <div class="joinbg_li li_s3">
                <div class="nicon icon-diamond"></div>
                <p>{{ $t("store.footer04_title") }}</p>
              </div>
              <!-- 加入會員 -->
              <div class="modal-main joinus">
                <div class="title">{{ $t("store.user.join") }}</div>
                <b-form @submit.stop.prevent="onSubmit">
                  
                  <template v-if="ShowUpagent">
                    <b-form-group :label="$t('store.user.agent')">
                      <b-form-input :value="form.upagent" readonly />
                    </b-form-group>
                  </template>
                  
                  <template v-if="ShowDealer">
                    <b-form-group
                      :label="$t('store.user.agent')"
                      label-for="input-dealer"
                    >
                      <b-form-input
                        v-model="form.dealer"
                        id="input-dealer"
                        name="dealer"
                        :state="validateState('dealer')"                      
                      />
                      <b-form-invalid-feedback>
                        {{ $t(`store.user.agent`) }}{{ $t("store.tip_cant_required") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </template>

                  <template v-for="item in AccountSign">
                    <b-form-group
                      v-if="item.field == 'mobile' && AreaCode.length > 1"
                      :label="$t(`store.user.area_code`)"
                      :label-for="`input-area_code_id`"
                    >
                      <b-form-select v-model="$v.form.area_code_id.$model">
                        <Option v-for="value in AreaCode" :key="value.value" :value="value.value">
                          {{ value.text }}
                        </Option>
                      </b-form-select>
                    </b-form-group>

                    <b-form-group
                      v-if="item.field != 'dealer'"
                      :label="$t(`store.user.${item.field}`)"
                      :label-for="`input-${item.field}`"
                    >
                      <template v-if="item.field == 'account'">
                        <b-form-input
                          class="pdright"
                          :id="`input-${item.field}`"
                          :name="item.field"
                          :state="validateState(item.field)"
                          v-model="$v.form[item.field].$model"
                        />
                      </template>

                      <template v-else-if="item.field == 'mobile'">
                        <b-form-input
                          class="pdright"
                          :id="`input-${item.field}`"
                          :name="item.field"
                          :state="validateState(item.field)"
                          v-model="$v.form[item.field].$model"
                        />
                        <div class="form-inside" v-if="SignMobileCaptchas">
                          <button
                            type="button"
                            :style="ButSendMobileCode.disabled ? 'background-color: #dc3545;border-color: #dc3545;cursor: wait;' : ''"
                            :disabled="ButSendMobileCode.disabled"
                            @click="insideSendMobileCode"
                          >
                            {{ ButSendMobileCode.text }}
                          </button>
                        </div>
                      </template>

                      <template v-else-if="item.field == 'birthday'">
                        <b-form-input
                          :id="`input-${item.field}`"
                          type="date"
                          :name="item.field"
                          v-model="$v.form[item.field].$model"
                          :state="validateState(item.field)"
                        />
                      </template>

                      <template v-else-if="item.type == 'password'">
                        <b-form-input
                          :id="`input-${item.field}`"
                          :type="item.type"
                          :name="item.field"
                          v-model="$v.form[item.field].$model"
                          :state="validateState(item.field)"
                        />
                        <div class="pw-toggle" @click="changeIcon(item.field)"><i :class="pwIconData[item.field] || 'icon icon-eye'"></i></div>
                      </template>

                      <template v-else-if="item.field == 'w_code'">
                        <b-form-select
                          v-for="i in 4"
                          :key="i"
                          class="pin"
                          v-model="WCode[`${item.field}_${i}`]"
                        >
                          <Option v-for="n in 10" :key="n - 1" :value="n - 1">{{
                            n - 1
                          }}</Option>
                        </b-form-select>
                      </template>

                      <template v-else>
                        <b-form-input
                          :id="`input-${item.field}`"
                          :name="item.field"
                          v-model="$v.form[item.field].$model"
                          :state="validateState(item.field)"
                        />
                      </template>

                      <b-form-invalid-feedback>{{ $t(`store.user.${item.field}`) }}{{ $t("store.tip_cant_required") }}</b-form-invalid-feedback>
                      <b-form-text id="input-live-help" v-if="item.notice">
                        <i class="icon icon-infomation"></i>{{ $t(`store.user.${item.field}_notice`, { max: AccountLen }) }}
                      </b-form-text>
                    </b-form-group>

                    <template v-if="item.field == 'mobile' && SignMobileCaptchas">
                      <b-form-group :label="$t('store.btn.CAPTCHA')" label-for="input-usercaptcha">
                        <b-form-input
                          id="input-usercaptcha"
                          name="usercaptcha"
                          v-model="$v.form.usercaptcha.$model"
                          :state="validateState('usercaptcha')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-9-feedback">{{ $t("store.tip_cant_captcha_required") }}</b-form-invalid-feedback>
                      </b-form-group>
                    </template>
                  </template>

                  <template v-if="SignCaptchas">
                    <b-form-group :label="$t('store.btn.CAPTCHA')" label-for="input-usercaptcha">
                      <b-form-input
                        id="input-signcaptcha"
                        name="signcaptcha"
                        v-model="$v.form.signcaptcha.$model"
                        :state="validateState('signcaptcha')"
                      />
                      <b-form-invalid-feedback id="input-9-feedback">{{ $t("store.tip_cant_captcha_required") }}</b-form-invalid-feedback>
                      <img :src="SignCaptchasImg" @click="_changeSignCaptchas" class="signcaptcha_img" />
                    </b-form-group>
                  </template>

                  <hr />

                  <b-form-checkbox id="form-agreement" name="form-agreement" v-model="form.accepted">
                    {{ $t("store.get_agree") }}<b-link @click="ruleModal" ref="btnRule">{{ $t("store.terms") }}</b-link>
                  </b-form-checkbox>

                  <dialogRule />

                  <b-button type="submit" variant="submit">{{ $t("store.btn.submit_confirm") }}</b-button>

                </b-form>
              </div>

            </div>
          </div>
        </div>
      </template>
  </Layout>
</template>
<script>
  const Layout = require(`~/components/desktop/layouts/${process.env.DEF_webFrame}.vue`).default;
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import pageHead from "~/components/common/pageHead.vue";
  import dialogRule from "~/components/desktop/dialogRule.vue";
  export default {
    name: "signup",
    middleware: ["checkNoLogin"],
    mixins: [validationMixin],
    components: {
      Layout,
      pageHead,
      dialogRule,
    },
    data() {
      return {
        title: this.$t("store.btn.sign"),
        pwIconData: {
          'password': "icon icon-eye",
          'password_confirmation': "icon icon-eye"
        },
        isSubmit: false,
        form: {
          account: null,
          password: null,
          password_confirmation: null,
          name: null,
          nickname: null,
          area_code_id: 1,
          mobile: null,
          email: null,
          w_code: null,
          birthday: null,
          line_id: null,
          whats_app: null,
          usercaptcha: null,
          signcaptcha: null,
          upagent: null,
          dealer: null,
          accepted: false,
        },
        WCode: {
          w_code_1: 0,
          w_code_2: 0,
          w_code_3: 0,
          w_code_4: 0,
        },
        ButCheckAccount: { disabled: false, check: false },
        ButSendMobileCode: this.handleButSendMobileCode(),
      };
    },
    validations() {
      let form = {
        account: {},
        password: {},
        password_confirmation: {},
        name: {},
        nickname: {},
        area_code_id: {},
        mobile: {},
        email: {},
        w_code: {},
        birthday: {},
        line_id: {},
        whats_app: {},
        usercaptcha: {},
        signcaptcha: {},
        upagent: {},
        dealer: {},
        agree: {},
      };

      if (this.AccountSign) {
        for (var item in this.AccountSign) {
          if (Object.keys(form).indexOf(item) < 0)
            form[this.AccountSign[item].field] = {};
        }
      }
      
      if (this.AccountSignValidations) {
        for (var item in this.AccountSignValidations) {
          if (Object.keys(form).indexOf(item) >= 0)
            form[item] = this.AccountSignValidations[item];
        }
      }

      return { form };
    },
    methods: {
      changeIcon(field){
        const pw = document.getElementById(`input-${field}`);
        if(pw.type === 'password'){
          pw.setAttribute('type','text');
          this.pwIconData[field] = 'icon icon-noeye';
        }else{
          pw.setAttribute('type','password');
          this.pwIconData[field] = 'icon icon-eye';
        }
      },
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      onSubmit() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) { return; }

        const { account, password, accepted } = this.$v.form.$model;
        if (this.WebSetting.MasterAccount != "mobile") {
          if (account == password) {
            this._showMsgBox({ title: this.$t("store.tip"), msg: this.$t("store.pw.tip_idpw_not_same") });
            return;
          }
        }

        if (!this.ButSendMobileCode.check && this.SignMobileCaptchas) {
          this._showMsgBox({ title: this.$t("store.tip"), msg: this.$t("store.tip_mobile_captcha_first") });
          return;
        }

        if (!accepted) {
          this._showMsgBox({ title: this.$t("store.tip"), msg: this.$t("store.tip_plz_agree") });
          return;
        }

        if (this.isSubmit)
          return;

        if (this.UpAgentCode)
          this.$v.form.$model.upagent = this.UpAgentCode;

        if (this.AccountLineCode)
          this.$v.form.$model.bind_line = this.AccountLineCode;

        this.isSubmit = true;
        this.AccountFrontInsert(this.$v.form.$model).then((res) => {
          this.SendGtmEnent(res);
          this._changeSignCaptchas();
          this.isSubmit = false;
          if (res) {
            if (this.AccountLineCode)
              this._setLineCode(null);
            if (this.UpAgentLineCode)
              this._setUpAgentLineCode(null);
            if (this.UpAgentCode)
              this._setUpAgentCode(null);

            this.$router.replace({ path: "/" });
          }
        });
      },
      ruleModal() {
        this.$root.$emit("bv::toggle::modal", "modalRule", "#ModalRuleRef");
      },
      SendGtmEnent(IsSuccess) {
        let GtmData = {
          event: IsSuccess ? 'registration successful' : 'registration failed',
          msg: IsSuccess ? 'Success' : 'Fail'
        };
        if (!IsSuccess) {
          GtmData.user_info = this.form;
          delete GtmData.user_info.password;
          delete GtmData.user_info.password_confirmation;
          delete GtmData.user_info.w_code;
        }

        this.$gtm.push(GtmData);
      },
      insideCheckAccount: function () {
        if (this.ButCheckAccount.check) {
          this._showMsgBox({ title: this.$t("store.tip"), msg: this.$t("store.tip_id_ok") });
          return;
        }

        this.$v.form.account.$touch();
        if (this.validateState("account")) {
          this.ButCheckAccount.disabled = true;
          const { account } = this.$v.form.$model;
          this.CheckAccount({ account }).then((res) => {
            if (res) this.ButCheckAccount.check = true;
            this.ButCheckAccount.disabled = false;
          });
        }
      },
      insideSendMobileCode: function () {
        if (this.ButSendMobileCode.disabled) {
          this._showMsgBox({ title: this.$t("store.tip"), msg: this.$t("store.tip_mobile_captcha_send") });
          return;
        }

        this.$v.form.mobile.$touch();
        if (this.validateState("mobile")) {
          this.ButSendMobileCode.disabled = true;
          const { mobile, area_code_id } = this.$v.form.$model;
          this.SendMobileCode({ mobile, area_code_id }).then((res) => {
            if (parseFloat(res).toString() != "NaN") {
              this.setReciprocal(res);
            } else if (res) {
              this.ButSendMobileCode.check = true;
              this.setReciprocal(this.ButSendMobileCode.seconds);
            } else {
              this.ButSendMobileCode.disabled = false;
            }
          });
        }
      },
      setReciprocal(seconds) {
        this.ButSendMobileCode.reciprocal = seconds;
        this.ButSendMobileCode.timer = setInterval(() => {
          if (this.ButSendMobileCode.reciprocal > 0) {
            this.ButSendMobileCode.reciprocal--;
            this.ButSendMobileCode.text = `${this.ButSendMobileCode.reciprocal}` + this.$t("store.tip_mobile_second_reset");
          } else {
            this.ButSendMobileCode.disabled = false;
            this.ButSendMobileCode.text = this.$t("store.btn.verify_btn");
            clearInterval(this.ButSendMobileCode.timer);
          }
        }, 1000);
      },
      setWCode() {
        let WCode = "";
        WCode += this.WCode.w_code_1 ? this.WCode.w_code_1 : "0";
        WCode += this.WCode.w_code_2 ? this.WCode.w_code_2 : "0";
        WCode += this.WCode.w_code_3 ? this.WCode.w_code_3 : "0";
        WCode += this.WCode.w_code_4 ? this.WCode.w_code_4 : "0";
        this.$v.form["w_code"].$model = WCode;
      },
      handleButSendMobileCode() {
        return {
          disabled: false,
          check: false,
          seconds: 60 * 1,
          reciprocal: 0,
          text: this.$t("store.btn.verify_btn"),
          timer: null,
        };
      },
      changeLang() {
        this.ButSendMobileCode = this.handleButSendMobileCode();
        this.title = this.$t("store.btn.sign");
      },
      getUpAgentCode() {
        if (this.AccountLineCode && this.UpAgentLineCode)
          return this.UpAgentLineCode;

        let Code        = null;
        let AgentCode   = this.$store.state.UpAgentCode
        let CookiesCode = this.$cookies.get("UpAgentCode");
        let StorageCode = typeof window !== 'undefined' ? localStorage.getItem('UpAgentCode') : null;;

        if (StorageCode && StorageCode != null && StorageCode != '')
          Code = StorageCode;
        if (CookiesCode && CookiesCode != null && CookiesCode != '')
          Code = CookiesCode;
        if (AgentCode && AgentCode != null && AgentCode != '')
          Code = AgentCode;

        if (Code != AgentCode)
          this._setUpAgentCode(Code);

        return Code;
      },
      handleInsideForm () {
        if (this.AccountSign) {
          for (var item in this.AccountSign) {
            if (Object.keys(this.form).indexOf(this.AccountSign[item].field) < 0)
              this.$set(this.form, this.AccountSign[item].field, null);
          }
        }

        if (Object.keys(this.form).indexOf('upagent') >= 0)
          this.form.upagent = this.getUpAgentCode();
        if (Object.keys(this.form).indexOf('dealer') >= 0)
          this.form.dealer = this.getUpAgentCode();
      },
      ...mapActions(["_showMsgBox", '_changeSignCaptchas']),
      ...mapActions("account", ["_getAccountSign", "_getAreaCode", "CheckAccount", "SendMobileCode", "AccountFrontInsert", "GetAccountWallet"]),
      ...mapMutations(["_setUpAgentCode", "_setUpAgentLineCode"]),
      ...mapMutations('login', ['_setLineCode']),
    },
    mounted() {
      this._getAccountSign().then((res) => { this.handleInsideForm(); });
      this._changeSignCaptchas();
      this._getAreaCode();
      this.setWCode();
      this.changeLang();
      this.$nextTick(function () {
        const sound_btn = document.getElementById('sound_btn');
        if (sound_btn)
          sound_btn.click();
      });
    },
    watch: {
      "$v.form.account.$model"(value) {
        if (this.ButCheckAccount.check) this.ButCheckAccount.check = false;
      },
      "$v.form.area_code.$model"(value) {
        if (this.ButSendMobileCode.check) this.ButSendMobileCode.check = false;
      },
      "$v.form.mobile.$model"(value) {
        if (this.ButSendMobileCode.check) this.ButSendMobileCode.check = false;
      },
      "WCode.w_code_1"() {
        this.setWCode();
      },
      "WCode.w_code_2"() {
        this.setWCode();
      },
      "WCode.w_code_3"() {
        this.setWCode();
      },
      "WCode.w_code_4"() {
        this.setWCode();
      },
      "$i18n.locale"(language) {
        this.changeLang();
      },
      UpAgentCode (Value) {
        this.$v.form.$model.upagent = this.getUpAgentCode();
      },
    },
    computed: {
      AccountSignMobile() {
        return this.$store.getters["account/AccountSignInfo"]("mobile");
      },
      AccountSignAreaCode() {
        return this.$store.getters["account/AccountSignInfo"]("area_code");
      },
      AccountLen() {
        return parseInt(Object.keys(this.WebSetting).indexOf('ACCOUNT_LEN') >= 0 ? this.WebSetting.ACCOUNT_LEN : 10);
      },
      SignCaptchas() {
        return Object.keys(this.WebSetting).indexOf('SIGN_CAPTCHAS') >= 0 && this.WebSetting.SIGN_CAPTCHAS == '1';
      },
      SignMobileCaptchas() {
        return Object.keys(this.WebSetting).indexOf('SIGN_MOBILE_CAPTCHAS') >= 0 && this.WebSetting.SIGN_MOBILE_CAPTCHAS == '1';
      },
      ShowUpagent() {
        return !this.ShowDealer && this.$v.form.$model.upagent;
      },
      ShowDealer() {
        let Data = this.AccountSign.find(Item => Item.field === 'dealer');
        if (!Data)
          return false;

        return Data.show == '1' || Data.required == '1';
      },
      ...mapState(["WebSetting", 'SignCaptchasImg', 'UpAgentCode', 'UpAgentLineCode']),
      ...mapState("login", ['AccountLineCode']),
      ...mapGetters("account", ["AccountSign", "AreaCode", "AccountSignValidations"]),
    }

  };
</script>
